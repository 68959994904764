const menu = [
	{
		name: 'Dashboard',
		to: { name: 'home' },
		dropdown: false,
		roles: 'any',
	},
	{
		name: 'Campaign',
		dropdown: true,
		roles: 'any',
		child: [
			{
				name: 'Campaign list',
				to: { name: 'campaign' },
				roles: 'any',
			},
			{
				name: 'Campaign result',
				to: { name: 'campaign-result' },
				roles: 'any',
			},
			{
				name: 'Campaign landing',
				to: { name: 'campaign-landing' },
				roles: 'any',
			},
			{
				name: 'Campaign additional configs',
				to: { name: 'campaign-additional-configs' },
				roles: 'any',
			},
		],
	},
	{
		name: 'Reward',
		to: { name: 'reward' },
		dropdown: false,
		roles: 'any',
	},
	{
		name: 'Admin',
		dropdown: true,
		roles: ['super-admin', 'super-viewer'],
		child: [
			{
				name: 'Admin management',
				to: { name: 'admin' },
				roles: ['super-admin', 'super-viewer'],
			},
			{
				name: 'Company management',
				to: { name: 'company' },
				roles: ['super-admin', 'super-viewer'],
			},
			{
				name: 'User management',
				to: { name: 'user' },
				roles: ['super-admin', 'super-viewer'],
			},
		],
	},
	{
		name: 'System',
		dropdown: true,
		roles: ['super-admin', 'super-viewer'],
		child: [
			{
				name: 'Logs',
				to: { name: 'log' },
				roles: ['super-admin', 'super-viewer'],
			},
		],
	},
	{
		name: 'Report',
		roles: 'any',
		to: { name: 'report-index' },
	},
	{
		name: 'User',
		roles: '!super',
		to: { name: 'user' },
	},
]

export default menu
