import dayjs from 'dayjs'

export const checkValidDescGroupFilter = {
	year: (from, to) => {
		return dayjs(from).add(3, 'year').isBefore(dayjs(to))
			? {
					ok: false,
					message: `Max 3 years (now ${Math.ceil(
						dayjs(to).diff(dayjs(from), 'year', true),
					)} years)`,
			  }
			: {
					ok: true,
					message: 'OK',
			  }
	},
	month: (from, to) => {
		return dayjs(from).add(12, 'month').isBefore(dayjs(to))
			? {
					ok: false,
					message: `Max 12 months (now ${Math.ceil(
						dayjs(to).diff(dayjs(from), 'month', true),
					)} months)`,
			  }
			: {
					ok: true,
					message: 'OK',
			  }
	},
	day: (from, to) => {
		return dayjs(from).add(90, 'day').isBefore(dayjs(to))
			? {
					ok: false,
					message: `Max 90 days (now ${Math.ceil(
						dayjs(to).diff(dayjs(from), 'day', true),
					)} days)`,
			  }
			: {
					ok: true,
					message: 'OK',
			  }
	},
	hour: (from, to) => {
		return dayjs(from).add(7, 'day').isBefore(dayjs(to))
			? {
					ok: false,
					message: `Max 7 days (now ${Math.ceil(
						dayjs(to).diff(dayjs(from), 'day', true),
					)} days)`,
			  }
			: {
					ok: true,
					message: 'OK',
			  }
	},
}

const filters = {
	install(Vue) {
		Vue.filter('dateServer', function (value) {
			if (!value) {
				return ''
			}
			return dayjs(value).format('DD/MM/YYYY')
		})
		Vue.filter('date', function (value) {
			if (!value) {
				return '-'
			}
			return dayjs(value).format('DD/MM/YYYY')
		})
		Vue.filter('dateTime', function (value) {
			if (!value) {
				return '-'
			}
			return dayjs(value).format('DD/MM/YYYY HH:mm:ss')
		})
		Vue.filter('jsDateToStr', function (value) {
			if (value == null) {
				return '-'
			}
			return dayjs(value).format('YYYY-MM-DD')
		})
		Vue.filter('descGroupFilter', function (value) {
			switch (value) {
				case 'hour':
					return 'max 7 days'
				case 'day':
					return 'max 90 days'
				case 'month':
					return 'max 12 months'
				case 'year':
					return 'max 3 years'
				default:
					return ''
			}
		})
		Vue.filter('number', function (value) {
			if (value == null) {
				return '-'
			}
			return (+parseFloat(value).toFixed(2)).toLocaleString()
		})
		Vue.filter('percent', function (value) {
			if (value === null) {
				return '-'
			}
			if (value === '+Inf' || value === '-Inf') {
				return value
			}
			return (Math.floor(+value * 100) / 100).toLocaleString()
		})
	},
}

export default filters
