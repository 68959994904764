import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { useAuthStore } from '@/store/authStore'
import Highcharts from 'highcharts'
import mapInit from 'highcharts/modules/heatmap'

import filters from './plugins/filters'
import vSelect from 'vue-select'
import VueToast from 'vue-toast-notification'
import Swal from 'sweetalert2'
import VCalendar from 'v-calendar'
import LuckDraw from '@lucky-canvas/vue'
import './plugins/VeeValidate'

import 'sweetalert2/src/sweetalert2.scss'
import 'vue-toast-notification/dist/theme-default.css'
import 'vue-select/dist/vue-select.css'
import './assets/scss/main.scss'
import './assets/tailwind.css'
import './assets/style/main.scss'

Vue.config.productionTip = false

mapInit(Highcharts)
Highcharts.setOptions({
	lang: {
		thousandsSep: ',',
	},
	colors: [
		'#002E85',
		'#F22634',
		'#2f9fb1',
		'#7798BF',
		'#539da9',
		'#011232',
		'#7357ab',
		'#861D13',
		'#2658a5',
		'#7798BF',
		'#aaeeee',
	],
	credits: {
		enabled: false,
	},
})

Vue.use(filters)
Vue.use(VueToast, {
	position: 'top-right',
	duration: 2000,
})
Vue.use(VCalendar)
Vue.use(PiniaVuePlugin)
Vue.use(LuckDraw)
const pinia = createPinia()
Vue.component('v-select', vSelect)

Vue.mixin({
	data() {
		return {
			isLoading: false,
			paginate: {
				count: 0,
				page: 1,
				perPage: 30,
			},
			currentPage: 1,
			sortField: 'created_at desc',
		}
	},
	methods: {
		canView(roles) {
			let auth = {
				'super-admin': useAuthStore().isSuperAdmin,
				'super-viewer': useAuthStore().isSuperViewer,
				'!super-admin': !useAuthStore().isSuperAdmin,
				'!super-viewer': !useAuthStore().isSuperViewer,
				admin: useAuthStore().isAdmin,
				operator: useAuthStore().isOperator,
				'!admin': !useAuthStore().isAdmin,
				'!operator': !useAuthStore().isOperator,
				super: useAuthStore().isSuper,
				editor: useAuthStore().isEditor,
				'!super': !useAuthStore().isSuper,
				'!editor': !useAuthStore().isEditor,
				any: true,
			}
			if (Array.isArray(roles)) {
				const res = roles.find((r) => {
					if (auth[r]) {
						return auth[r]
					}
					return false
				})
				return !!res
			}
			return auth[roles] || false
		},
		setLoading(value) {
			this.isLoading = value
		},
		async useConfirm({ title, text, icon }) {
			const result = await Swal.fire({
				title: title || 'Are you sure?',
				text: text || 'Confirm to delete',
				icon: icon || 'warning',
				focusConfirm: false,
				showCancelButton: true,
				buttonsStyling: false,
				customClass: {
					cancelButton: 'button text-white bg-red-400 border-0',
					confirmButton: 'button is-primary mr-3 border-0',
					actions: 'mt-5',
				},
			})

			return result.value
		},
		cloneObject(value) {
			return JSON.parse(JSON.stringify(value))
		},
		sortTable($event, field, separator = ' ') {
			document.querySelectorAll('.sortable').forEach((el) => {
				if (el !== $event.target) {
					el.classList.remove('sortup')
					el.classList.remove('sortdown')
				}
			})
			const classList = $event.target.classList
			let sort = ''
			if (classList.contains('sortup')) {
				classList.remove('sortup')
				classList.add('sortdown')
				sort = separator + 'desc'
			} else if (classList.contains('sortdown')) {
				classList.remove('sortdown')
				classList.add('sortup')
				sort = separator + 'asc'
			} else {
				sort = separator + 'asc'
				classList.add('sortup')
			}
			this.sortField = field + sort
		},
		getFromDate(group, to) {
			if (to.isSame(to.endOf('month'), 'day')) {
				if (group === 'month') {
					return to.startOf('month')
				} else if (group === 'year') {
					return to.subtract(11, 'month').startOf('month')
				}
			}

			const type = {
				hour: 'day',
				day: 'day',
				month: 'month',
				year: 'year',
			}[group]
			return to.subtract(1, type).add(1, 'day')
		},
		getFromDaily(group, to) {
			if (to.isSame(to.endOf('month'), 'day')) {
				if (group === 'monthly') {
					return to.startOf('month')
				} else if (group === 'yearly') {
					return to.subtract(11, 'month').startOf('month')
				}
			}

			const type = {
				daily: 'day',
				monthly: 'month',
				yearly: 'year',
			}[group]
			return to.subtract(1, type).add(1, 'day')
		},
	},
})

new Vue({
	router,
	pinia,
	render: (h) => h(App),
}).$mount('#app')
