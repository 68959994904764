<script>
export default {
	props: {
		menu: {
			type: Object,
			required: true,
		},
	},

	methods: {
		getChildrenRoutes(m) {
			return m?.child?.map((it) => it?.to.name) || []
		},
	},
}
</script>

<template>
	<div v-if="canView(menu.roles)">
		<router-link v-if="menu.to" :to="menu.to">
			<a class="nm-button is-variant-ghost px-3">
				{{ menu.name }}
			</a>
		</router-link>

		<div v-else class="nm-dropdown is-hover-open">
			<a
				class="nm-button is-variant-ghost px-3"
				:class="{
					'is-active': getChildrenRoutes(menu).includes($route.name),
				}"
			>
				{{ menu.name }} <i class="fa-regular fa-chevron-down _mgl-4"></i>
			</a>

			<ul class="nm-menu is-card _bsd-100 _bdw-1 _bdcl-content _bdcl-opacity-15 _bdrd-4">
				<template v-for="child in menu.child">
					<li :key="child.name" v-if="canView(child.roles)">
						<router-link :to="child.to" class="_wsp-nw">
							{{ child.name }}
						</router-link>
					</li>
				</template>
			</ul>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.router-link-exact-active {
	.nm-button.is-variant-ghost {
		background: hsl(var(--hsl-white) / 0.1);
	}
}

.nm-button.is-variant-ghost {
	color: hsl(var(--hsl-white));

	.router-link-exact-active,
	&.is-active {
		background: hsl(var(--hsl-white) / 0.1);
	}

	&:hover {
		background: hsl(var(--hsl-white) / 0.1);
	}
}
</style>
