import api from '@/api'
import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth-store', {
	state: () => {
		return {
			me: {
				id: null,
				username: '',
				// superAdmin: false,
				role: 0, // 1 Super admin, 2 Super Viewer, 3 Admin, 4 Operator
				roleDisplay: '',
				company: {
					id: null,
					name: '',
					logo: '',
					theme: {
						font: null,
						color: '#000',
					},
					lineLiffId: '',
					lineSecret: '',
					introspectUrl: '',
					pointApiUrl: '',
				},
			},
			isLoggedIn: false,
			isInit: false,
		}
	},
	getters: {
		hideMainEntryPoint: (state) => state?.me?.company?.pointApiUrl === '',
		isSuper: (state) => [1, 2].includes(state?.me?.role),
		isSuperAdmin: (state) => state?.me?.role === 1,
		isSuperViewer: (state) => state?.me?.role === 2,
		isAdmin: (state) => state?.me?.role === 3,
		isOperator: (state) => state?.me?.role === 4,
		isEditor: (state) => [1, 3].includes(state?.me?.role),
		myCompanyId: (state) => state?.me?.company?.id || null,
		companyTheme: (state) => state?.me?.company?.theme,
	},
	actions: {
		getMe() {
			if (this.isInit) {
				return
			}
			this.isInit = true
			return api
				.me()
				.then((res) => {
					this.me = res.data
					this.isLoggedIn = true
					return res
				})
				.catch(() => {
					this.isInit = false
				})
		},
		signoutHandler() {
			this.isInit = false
			return api.signout()
		},
	},
})
