import axios from 'axios'
import router from '@/router/index.js'
import Vue from 'vue'

const authType = 'token'

if (authType === 'token') {
	axios.interceptors.request.use(
		function (config) {
			const token = localStorage.getItem('token')
			if (token) {
				config.headers.common['authorization'] = `bearer ${token}`
			}
			return config
		},
		function (error) {
			return Promise.reject(error)
		},
	)
}

axios.interceptors.response.use(
	function (response) {
		return response
	},
	function (error) {
		if (error?.response?.status === 401) {
			router.push({ name: 'signin' })
		}
		let message = error?.response?.data?.error?.message || 'Internal Server Error'
		if (message) {
			Vue.$toast.error(message)
		}
		return Promise.reject(error)
	},
)

export const baseAPIDomain = process.env.VUE_APP_API // base domain for API uat
export const baseAPPLineAPIDomain = process.env.VUE_APP_LINE_API // base domain for API uat
//export const baseAPIDomain = '/test' // base domain for API localhost:8080
export const baseAPPLineDomain = process.env.VUE_APP_LINE_DOMAIN // base domain for application
const bucketPath = process.env.VUE_APP_FILE + '/'
const campaignDomain = (path) => {
	return `${baseAPPLineDomain}/${path}`
}
const lineCampaignDomain = (liffId, path) => {
	return `https://liff.line.me/${liffId}${path}`
}

axios.defaults.baseURL = baseAPIDomain

let signin = (req) => {
	return axios.post('/auth/signin', req)
}
let signout = () => {
	return axios.post('/auth/signout')
}

if (authType === 'token') {
	signin = ({ username, password }) => {
		return axios
			.post('/auth/signin', {
				username,
				password,
			})
			.then((resp) => {
				if (resp.data?.token) {
					localStorage.setItem('token', resp.data.token)
				}
				return resp
			})
	}

	signout = () => {
		return axios
			.post('/auth/signout', {
				token: localStorage.getItem('token'),
			})
			.then((resp) => {
				localStorage.removeItem('token')
				return resp
			})
	}
}

const me = () => {
	return axios.get('/auth/me')
}

const uploadFile = async (file) => {
	const formData = new FormData()
	formData.append('file', file)

	try {
		const res = await axios.post('/files', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		return bucketPath + res.data.filename
	} catch (err) {
		Vue.$toast.error('Upload file error.')
		throw new Error(err)
	}
}

const uploadVideo = async (file, config = {}) => {
	const formData = new FormData()
	formData.append('file', file)

	try {
		const res = await axios.post('/campaigns/watch-video/upload', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			...config,
		})
		return bucketPath + res.data.filename
	} catch (err) {
		Vue.$toast.error('Upload file video error.')
		throw new Error(err)
	}
}

const adminUserList = ({ page = 1, perPage = 30 }) => {
	return axios.get('/backoffice-users', {
		params: {
			page,
			perPage,
		},
	})
}
const adminUserGet = (id) => {
	return axios.get('/backoffice-users/' + id)
}
const adminUserCreate = (data) => {
	return axios.post('/backoffice-users', data)
}
const adminUserEdit = (id, data) => {
	return axios.put('/backoffice-users/' + id, data)
}
const adminUserDelete = (id) => {
	return axios.delete('/backoffice-users/' + id)
}

const companyList = ({
	page = 1,
	perPage = 30,
	sortField = 'created_at desc',
	startDate = '',
	endDate = '',
	filter = '',
}) => {
	return axios.get('/companies', {
		params: {
			page,
			perPage,
			sortField,
			startDate,
			endDate,
			filter,
		},
	})
}
const companyGet = (id) => {
	return axios.get('/companies/' + id)
}
const companyCreate = (data) => {
	return axios.post('/companies', data)
}
const companyEdit = (id, data) => {
	return axios.put('/companies/' + id, data)
}
const companyDelete = (id) => {
	return axios.delete('/companies/' + id)
}
const companyEditProfile = (data) => {
	return axios.put('/auth/company', data)
}

const getCampaignDashboard = (id, data) => {
	return axios.get('/dashboard/campaigns/' + id, {
		params: data,
	})
}

const userList = ({
	page = 1,
	perPage = 30,
	order = 'created_at desc',
	startDate = '',
	endDate = '',
	filter = '',
}) => {
	return axios.get('/user-profile', {
		params: {
			page,
			perPage,
			order,
			startDate,
			endDate,
			filter,
		},
	})
}
const userGet = (id) => {
	return axios.get('/user-profile/' + id)
}
const userUploadCSV = async (file, companyID = '') => {
	const formData = new FormData()
	formData.append('file', file)

	try {
		const res = await axios.post(
			`/user-profile/companies/${companyID}/import-csv-file`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		)
		return res
	} catch (err) {
		Vue.$toast.error('Upload file error.')
		throw new Error(err)
	}
}

const campaignList = ({
	page = 1,
	perPage = 30,
	sortField = 'cam.created_at desc',
	startDate = '',
	endDate = '',
	filter = '',
}) => {
	return axios.get('/campaigns', {
		params: {
			page,
			perPage,
			sortField,
			startDate,
			endDate,
			filter,
		},
	})
}
const campaignGet = (id) => {
	return axios.get('/campaigns/' + id)
}
const campaignCreate = (data) => {
	return axios.post('/campaigns', data)
}
const campaignEdit = (id, data) => {
	return axios.put('/campaigns/' + id, data)
}
const campaignDelete = (id) => {
	return axios.delete('/campaigns/' + id)
}
const campaignAnnouncementGet = (id) => {
	return axios.get('/campaigns/' + id + '/announcement')
}
const campaignAnnouncementEdit = (id, data) => {
	return axios.put('/campaigns/' + id + '/announcement', data)
}
const campaignAnnouncementLuckydrawGet = (id) => {
	return axios.get('/campaigns/' + id + '/announcement/lucky-draw')
}
const campaignAnnouncementLuckydrawEdit = (id, data) => {
	return axios.put('/campaigns/' + id + '/announcement/lucky-draw', data)
}
const campaignAnnouncementType2DownloadExcel = async (campaignId) => {
	const res = await axios.get(
		'/campaigns/' + campaignId + '/user/announcement-type-2/export-data',
		{
			responseType: 'blob',
		},
	)
	const blob = new Blob([res.data], { type: 'text/csv; charset=utf-8', encoding: 'UTF-8' })
	const link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = res.headers['content-disposition'].split('filename=')[1]
	link.click()
}
const campaignAnnouncementType3DownloadExcel = async (campaignId) => {
	const res = await axios.get(
		'/campaigns/' + campaignId + '/user/announcement-type-3/export-data',
		{
			responseType: 'blob',
		},
	)
	const blob = new Blob([res.data], { type: 'text/csv; charset=utf-8', encoding: 'UTF-8' })
	const link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = res.headers['content-disposition'].split('filename=')[1]
	link.click()
}
const campaignAnnouncementType1LuckyRewardDownloadExcel = async (campaignId) => {
	const res = await axios.get(
		'/campaigns/' + campaignId + '/user/lucky-draw/announcement-type-1/export-data',
		{
			responseType: 'blob',
		},
	)
	const blob = new Blob([res.data], { type: 'text/csv; charset=utf-8', encoding: 'UTF-8' })
	const link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = res.headers['content-disposition'].split('filename=')[1]
	link.click()
}
const campaignAnnouncementType2LuckyRewardDownloadExcel = async (campaignId) => {
	const res = await axios.get(
		'/campaigns/' + campaignId + '/user/lucky-draw/announcement-type-2/export-data',
		{
			responseType: 'blob',
		},
	)
	const blob = new Blob([res.data], { type: 'text/csv; charset=utf-8', encoding: 'UTF-8' })
	const link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = res.headers['content-disposition'].split('filename=')[1]
	link.click()
}
const campaignUserResultGet = (campaignId, params) => {
	return axios.get('/campaigns/' + campaignId + '/user/result', {
		params,
	})
}
const campaignResultListByCompanyId = (companyId) => {
	return axios.get('/campaigns/companies/' + companyId + '/campaign-results')
}
const campaignAdditionalConfigsByCompanyId = (companyId) => {
	return axios.get('/campaigns/companies/' + companyId + '/campaign-additional-configs')
}
const campaignAdditionalResult = (companyId, params) => {
	return axios.get('/campaigns/' + companyId + '/user/additional-result', {
		params,
	})
}
const campaignAdditionalResultDownloadExcel = async (campaignId) => {
	const res = await axios.get('/campaigns/' + campaignId + '/user/additional/export-data', {
		responseType: 'blob',
	})
	const blob = new Blob([res.data], { type: 'text/csv; charset=utf-8', encoding: 'UTF-8' })
	const link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = res.headers['content-disposition'].split('filename=')[1]
	link.click()
}
const campaignRewardListByCompanyId = ({
	companyId,
	page = 1,
	perPage = 30,
	search = '',
	list = 'true',
}) => {
	return axios.get('/campaigns/companies/' + companyId + '/rewards', {
		params: {
			search,
			page,
			perPage,
			list,
		},
	})
}
const campaignUserResultDownloadExcel = async (campaignId, campaignType) => {
	const res = await axios.get(
		'/campaigns/' + campaignId + '/user/result/export-data/' + campaignType,
		{
			responseType: 'blob',
		},
	)
	const blob = new Blob([res.data], { type: 'text/csv; charset=utf-8', encoding: 'UTF-8' })
	const link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = res.headers['content-disposition'].split('filename=')[1]
	link.click()
}

const campaignCodeSettingImportData = async (campaignId, file) => {
	const formData = new FormData()
	formData.append('file', file)

	try {
		const res = await axios.post(
			'/campaigns/' + campaignId + '/point-collection-code/import-data',
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		)
		return res
	} catch (err) {
		Vue.$toast.error('Upload file error.')
		throw new Error(err)
	}
}

const campaignCodeSettingImportDataForSubCampaign = async (dynamicId, subCampaignId, file) => {
	const formData = new FormData()
	formData.append('file', file)

	try {
		const res = await axios.post(
			`/campaigns/${subCampaignId}/dynamic/${dynamicId}/point-collection-code/import-data`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		)
		return res
	} catch (err) {
		Vue.$toast.error('Upload file error.')
		throw new Error(err)
	}
}

const campaignCodeSettingExportData = async (campaignId) => {
	const res = await axios.get('/campaigns/' + campaignId + '/point-collection-code/export-data', {
		responseType: 'blob',
	})
	const blob = new Blob([res.data], { type: 'text/csv; charset=utf-8', encoding: 'UTF-8' })
	const link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = 'code-setting-result-' + '.csv'
	link.click()
}

const campaignCodeSettingExportDataForSubCampaign = async (dynamicId, subCampaignId) => {
	const res = await axios.get(
		`/campaigns/${subCampaignId}/dynamic/${dynamicId}/point-collection-code/export-data`,
		{
			responseType: 'blob',
		},
	)
	const blob = new Blob([res.data], { type: 'text/csv; charset=utf-8', encoding: 'UTF-8' })
	const link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = 'code-setting-result-' + '.csv'
	link.click()
}

const campaignCodeSettingRemoveCode = async (campaignId, data) => {
	return axios.delete('/campaigns/' + campaignId + '/point-collection-code/delete-data', { data })
}

const campaignCodeSettingRemoveCodeForSubCampaign = async (dynamicId, subCampaignId, data) => {
	return axios.delete(
		`/campaigns/${subCampaignId}/dynamic/${dynamicId}/point-collection-code/delete-data`,
		{ data },
	)
}

const campaignCodeSettingGet = async (campaignId, data) => {
	return axios.post('/campaigns/' + campaignId + '/point-collection-code/get-data', data)
}

const campaignCodeSettingGetForSubCampaign = async (dynamicId, subCampaignId, data) => {
	return axios.post(
		`/campaigns/${subCampaignId}/dynamic/${dynamicId}/point-collection-code/get-data`,
		data,
	)
}

const campaignGetWebhookToken = async (campaignId) => {
	return axios.get('/campaigns/' + campaignId + '/webhook')
}

const campaignRotateWebhookToken = async (campaignId) => {
	return axios.put('/campaigns/' + campaignId + '/webhook')
}

const campaignResultList = ({
	page = 1,
	perPage = 30,
	sortField = 'camr.created_at desc',
	startDate = '',
	endDate = '',
	filter = '',
}) => {
	return axios.get('/campaign-results', {
		params: {
			page,
			perPage,
			sortField,
			startDate,
			endDate,
			filter,
		},
	})
}
const campaignResultGet = (id) => {
	return axios.get('/campaign-results/' + id)
}
const campaignResultCreate = (data) => {
	return axios.post('/campaign-results', data)
}
const campaignResultEdit = (id, data) => {
	return axios.put('/campaign-results/' + id, data)
}
const campaignResultDelete = (id) => {
	return axios.delete('/campaign-results/' + id)
}
const campaignResultLuckyRandom = (id, data) => {
	return axios.post('/campaigns/' + id + '/user/result/lucky-draw/random-list', data)
}

const campaignLandingList = ({
	page = 1,
	perPage = 30,
	sortField = 'cr.created_at desc',
	startDate = '',
	endDate = '',
	filter = '',
}) => {
	return axios.get('/campaign-landings', {
		params: {
			page,
			perPage,
			sortField,
			startDate,
			endDate,
			filter,
		},
	})
}
const campaignLandingGet = (id) => {
	return axios.get('/campaign-landings/' + id)
}
const campaignLandingCreate = (data) => {
	return axios.post('/campaign-landings', data)
}
const campaignLandingEdit = (id, data) => {
	return axios.put('/campaign-landings/' + id, data)
}
const campaignLandingDelete = (id) => {
	return axios.delete('/campaign-landings/' + id)
}

const campaignAdditionalConfigList = ({
	page = 1,
	perPage = 30,
	sortField = '',
	startDate = '',
	endDate = '',
	filter = '',
}) => {
	return axios.get('/campaign-additional-configs', {
		params: {
			page,
			perPage,
			sortField,
			startDate,
			endDate,
			filter,
		},
	})
}
const campaignAdditionalConfigGet = (id) => {
	return axios.get('/campaign-additional-configs/' + id)
}
const campaignAdditionalConfigCreate = (data) => {
	return axios.post('/campaign-additional-configs', data)
}
const campaignAdditionalConfigEdit = (id, data) => {
	return axios.put('/campaign-additional-configs/' + id, data)
}
const campaignAdditionalConfigDelete = (id) => {
	return axios.delete('/campaign-additional-configs/' + id)
}

const rewardList = ({
	page = 1,
	perPage = 30,
	sortField = 'rw.created_at desc',
	startDate = '',
	endDate = '',
	filter = '',
	list = '',
}) => {
	return axios.get('/rewards', {
		params: {
			page,
			perPage,
			sortField,
			startDate,
			endDate,
			filter,
			list,
		},
	})
}
const rewardGet = (id) => {
	return axios.get('/rewards/' + id)
}
const rewardCreate = (data) => {
	return axios.post('/rewards', data)
}
const rewardEdit = (id, data) => {
	return axios.put('/rewards/' + id, data)
}
const rewardDelete = (id) => {
	return axios.delete('/rewards/' + id)
}

const logList = ({ page = 1, perPage = 30 }) => {
	return axios.get('/backoffice-logs', {
		params: {
			page,
			perPage,
		},
	})
}
const logGet = (id) => {
	return axios.get('/backoffice-logs/' + id)
}

const getReportCompany = () => {
	return axios.get('/report/get-company-list')
}

const getCampaignByCompany = (companyId) => {
	return axios.post('/report/get-campaign-list-by-company', {
		companyId,
	})
}

const getDashboard = (data) => {
	return axios.get('/dashboard/campaigns/all/stat-box', {
		params: data,
	})
}

const getTopFiveCampaign = (data) => {
	return axios.get('/dashboard/campaigns/all/top-five', {
		params: data,
	})
}

const getDashboardUsedPoint = (data) => {
	return axios.get('/dashboard/campaigns/all/used-point', {
		params: data,
	})
}

const getDashboardUsedPointTopFive = (data) => {
	return axios.get('/dashboard/campaigns/all/used-point-top-five', {
		params: data,
	})
}

const getDashboardUsedPointHeatmap = (data) => {
	return axios.get('/dashboard/campaigns/all/used-point-heatmap', {
		params: data,
	})
}

const getDashboardAdditionalConfigTopFive = (data) => {
	return axios.get('/dashboard/campaigns/all/additional-config-top-five', {
		params: data,
	})
}

const getDashboardTopByRewardType = (data) => {
	return axios.get('/dashboard/campaigns/all/top-by-reward-type', {
		params: data,
	})
}

const getUserStatsReport = (companyId, { from, to, aggBy }) => {
	return axios.post('/report/members/user-stats', {
		companyId,
		aggBy,
		dateRange: {
			from,
			to,
		},
	})
}

const getUserChartReport = (companyId, { from, to, aggBy }) => {
	return axios.post('/report/members/user-chart', {
		companyId,
		aggBy,
		dateRange: {
			from,
			to,
		},
	})
}

const getCampaignReport = (companyId, { from, to }, { page = 1, perPage = 30 }) => {
	return axios.post(
		'/report/campaign',
		{
			companyId,
			dateRange: {
				from,
				to,
			},
		},
		{
			params: {
				page,
				perPage,
			},
		},
	)
}

const exportCampaignReport = (companyId, { from, to }) => {
	return axios.post(
		'/report/campaign/export',
		{
			companyId,
			dateRange: {
				from,
				to,
			},
		},
		{
			responseType: 'blob',
		},
	)
}

const getCompanyReport = ({ from, to }, { page = 1, perPage = 30 }) => {
	return axios.post(
		'/report/company',
		{
			dateRange: {
				from,
				to,
			},
		},
		{
			params: {
				page,
				perPage,
			},
		},
	)
}

const exportCompanyReport = (group, { from, to }) => {
	return axios.post(
		`report/company/${group}/export`,
		{
			dateRange: {
				from,
				to,
			},
		},
		{
			responseType: 'blob',
		},
	)
}

const getEntryPointStatsReport = (companyId, { from, to, aggBy }) => {
	return axios.post('/report/point/entry-point', {
		companyId,
		aggBy,
		dateRange: {
			from,
			to,
		},
	})
}

const getUsedPointTopTenReport = (companyId, { from, to }) => {
	return axios.post('/report/point/used-point-top-ten', {
		companyId,
		dateRange: {
			from,
			to,
		},
	})
}

const exportUsedPointTopTenReport = (companyId, { from, to }) => {
	return axios.post(
		'/report/point/used-point-top-ten/export',
		{
			companyId,
			dateRange: {
				from,
				to,
			},
		},
		{
			responseType: 'blob',
		},
	)
}

const getPointUsageInformationReport = (companyId, { from, to }, { page = 1, perPage = 30 }) => {
	return axios.post(
		'/report/point/usage-information',
		{
			companyId,
			dateRange: {
				from,
				to,
			},
		},
		{
			params: {
				page,
				perPage,
			},
		},
	)
}

const exportPointUsageInformationReport = (companyId, { from, to }) => {
	return axios.post(
		'/report/point/usage-information/export',
		{
			companyId,
			dateRange: {
				from,
				to,
			},
		},
		{
			responseType: 'blob',
		},
	)
}

const getRewardReport = ({ from, to }, { page = 1, perPage = 30 }) => {
	return axios.post(
		'/report/reward',
		{
			dateRange: {
				from,
				to,
			},
		},
		{
			params: {
				page,
				perPage,
			},
		},
	)
}

const exportRewardReport = ({ from, to }) => {
	return axios.post(
		'/report/reward/export',
		{
			dateRange: {
				from,
				to,
			},
		},
		{
			responseType: 'blob',
		},
	)
}

const getRewardUserResultReport = (
	companyId,
	campaignId,
	{ from, to },
	{ page = 1, perPage = 30 },
) => {
	return axios.post(
		'/report/reward/user-result',
		{
			companyId,
			campaignId,
			dateRange: {
				from,
				to,
			},
		},
		{
			params: {
				page,
				perPage,
			},
		},
	)
}

const exportRewardUserResultReport = (companyId, campaignId, { from, to }) => {
	return axios.post(
		'/report/reward/user-result/export',
		{
			companyId,
			campaignId,
			dateRange: {
				from,
				to,
			},
		},
		{
			responseType: 'blob',
		},
	)
}

const getAdditionalConfigReport = ({ from, to }, { page = 1, perPage = 30 }) => {
	return axios.post(
		'/report/additional-config',
		{
			dateRange: {
				from,
				to,
			},
		},
		{
			params: {
				page,
				perPage,
			},
		},
	)
}

const exportAdditionalConfigReport = ({ from, to }) => {
	return axios.post(
		'/report/additional-config/export',
		{
			dateRange: {
				from,
				to,
			},
		},
		{
			responseType: 'blob',
		},
	)
}

const getAdditionalConfigUserResultReport = (
	companyId,
	additionalConfigId,
	{ from, to },
	{ page = 1, perPage = 30 },
) => {
	return axios.post(
		'/report/additional-config/user-result',
		{
			companyId,
			additionalConfigId,
			dateRange: {
				from,
				to,
			},
		},
		{
			params: {
				page,
				perPage,
			},
		},
	)
}

const exportAdditionalConfigUserResultReport = (companyId, additionalConfigId, { from, to }) => {
	return axios.post(
		'/report/additional-config/user-result/export',
		{
			companyId,
			additionalConfigId,
			dateRange: {
				from,
				to,
			},
		},
		{
			responseType: 'blob',
		},
	)
}

const getTemplateByCompany = (companyId) => {
	return axios.post('/report/additional-config/list-by-company', {
		companyId,
	})
}

export default {
	baseAPPLineDomain,
	axios,
	signin,
	signout,
	me,
	uploadFile,
	uploadVideo,
	campaignDomain,
	lineCampaignDomain,

	adminUser: {
		list: adminUserList,
		get: adminUserGet,
		create: adminUserCreate,
		edit: adminUserEdit,
		delete: adminUserDelete,
	},

	company: {
		list: companyList,
		get: companyGet,
		create: companyCreate,
		edit: companyEdit,
		delete: companyDelete,
		editProfile: companyEditProfile,
	},

	user: {
		list: userList,
		get: userGet,
		uploadCSV: userUploadCSV,
	},

	campaign: {
		dashboard: getCampaignDashboard,
		list: campaignList,
		get: campaignGet,
		create: campaignCreate,
		edit: campaignEdit,
		delete: campaignDelete,
		userResultGet: campaignUserResultGet,
		announcementGet: campaignAnnouncementGet,
		announcementEdit: campaignAnnouncementEdit,
		announcementLuckydrawGet: campaignAnnouncementLuckydrawGet,
		announcementLuckydrawEdit: campaignAnnouncementLuckydrawEdit,
		announcementType2DownloadExcel: campaignAnnouncementType2DownloadExcel,
		announcementType3DownloadExcel: campaignAnnouncementType3DownloadExcel,
		announcementType1LuckyRewardDownloadExcel:
			campaignAnnouncementType1LuckyRewardDownloadExcel,
		announcementType2LuckyRewardDownloadExcel:
			campaignAnnouncementType2LuckyRewardDownloadExcel,
		resultListByCompanyId: campaignResultListByCompanyId,
		rewardListByCompanyId: campaignRewardListByCompanyId,
		userResultDownloadExcel: campaignUserResultDownloadExcel,
		getWebhookToken: campaignGetWebhookToken,
		rotateWebhookToken: campaignRotateWebhookToken,
		additionalConfigsByCompanyId: campaignAdditionalConfigsByCompanyId,
		additionalResult: campaignAdditionalResult,
		additionalResultDownloadExcel: campaignAdditionalResultDownloadExcel,
	},

	campaignCode: {
		import: campaignCodeSettingImportData,
		importForSubCampaign: campaignCodeSettingImportDataForSubCampaign,
		export: campaignCodeSettingExportData,
		exportForSubCampaign: campaignCodeSettingExportDataForSubCampaign,
		remove: campaignCodeSettingRemoveCode,
		removeForSubCampaign: campaignCodeSettingRemoveCodeForSubCampaign,
		get: campaignCodeSettingGet,
		getForSubCampaign: campaignCodeSettingGetForSubCampaign,
	},

	campaignResult: {
		list: campaignResultList,
		get: campaignResultGet,
		create: campaignResultCreate,
		edit: campaignResultEdit,
		delete: campaignResultDelete,
		luckyRandom: campaignResultLuckyRandom,
	},

	campaignLanding: {
		list: campaignLandingList,
		get: campaignLandingGet,
		create: campaignLandingCreate,
		edit: campaignLandingEdit,
		delete: campaignLandingDelete,
	},

	campaignAdditionalConfig: {
		list: campaignAdditionalConfigList,
		get: campaignAdditionalConfigGet,
		create: campaignAdditionalConfigCreate,
		edit: campaignAdditionalConfigEdit,
		delete: campaignAdditionalConfigDelete,
	},

	reward: {
		list: rewardList,
		get: rewardGet,
		create: rewardCreate,
		edit: rewardEdit,
		delete: rewardDelete,
	},

	log: {
		list: logList,
		get: logGet,
	},

	report: {
		getDashboard,
		getTopFiveCampaign,
		getDashboardUsedPoint,
		getDashboardUsedPointTopFive,
		getDashboardUsedPointHeatmap,
		getDashboardAdditionalConfigTopFive,
		getDashboardTopByRewardType,
		getCompany: getReportCompany,
		getCampaignByCompany: getCampaignByCompany,
		members: {
			getUserStatsReport,
			getUserChartReport,
		},
		campaign: {
			getCampaignReport,
			exportCampaignReport,
		},
		company: {
			getCompanyReport,
			exportCompanyReport,
		},
		entryPoint: {
			getEntryPointStatsReport,
			getUsedPointTopTenReport,
			exportUsedPointTopTenReport,
			getPointUsageInformationReport,
			exportPointUsageInformationReport,
		},
		reward: {
			getRewardReport,
			exportRewardReport,
			getRewardUserResultReport,
			exportRewardUserResultReport,
		},
		additionalConfig: {
			getAdditionalConfigReport,
			exportAdditionalConfigReport,
			getAdditionalConfigUserResultReport,
			exportAdditionalConfigUserResultReport,
			getTemplateByCompany,
		},
	},
}
