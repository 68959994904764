import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import {
	email,
	min,
	max,
	length,
	required,
	min_value,
	max_value,
	integer,
	alpha_num,
	between,
} from 'vee-validate/dist/rules'
import Vue from 'vue'
import dayjs from 'dayjs'

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)

extend('url', {
	validate(value) {
		if (value) {
			// eslint-disable-next-line no-useless-escape
			return /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/.test(
				value,
			)
		}

		return false
	},
	message: 'This value must be a valid URL',
})
extend('between', between)
extend('email', email)
extend('integer', integer)
extend('alpha_num', alpha_num)
extend('required', {
	...required,
	message: 'The {_field_} field is required.',
})
extend('min', {
	...min,
	params: ['length'],
	message: 'The {_field_} must be at least {length}.',
})
extend('max', {
	...max,
	params: ['length'],
	message: 'The {_field_} must not more than {length}.',
})
extend('length', {
	...length,

	message: 'The {_field_} must not more than {length}.',
})

extend('min_value', {
	...min_value,
	params: ['min'],
	message: 'The {_field_} field must be {min} or more.',
})

extend('max_value', {
	...max_value,
	params: ['max'],
	message: 'The {_field_} field must be {max} or less',
})

extend('timeBefore', {
	params: ['target'],
	validate(value, { target }) {
		return dayjs(value).isBefore(dayjs(target))
	},
	message: 'The {_field_} should be before {target}.',
})

extend('timeBefore24Hr', {
	params: ['target'],
	validate(value, { target }) {
		return (
			dayjs(value).isBefore(dayjs(target)) && dayjs(target).diff(dayjs(value), 'hour') >= 24
		)
	},
	message: 'The {_field_} should be before {target} more than 24 hr.',
})

extend('timeBeforeRangeByDate', {
	params: ['target'],
	validate(value, { target }) {
		return dayjs(value.end).isBefore(dayjs(target))
	},
	message: 'The end date of {_field_} should be before {target}.',
})

extend('timeBeforeRange', {
	params: ['target', 'index'],
	validate(value, { target }) {
		return dayjs(value.end).isBefore(dayjs(target))
	},
	message: 'The end date of {_field_} should be before start date of Day {index}.',
})

extend('timeAfter', {
	params: ['target'],
	validate(value, { target }) {
		return dayjs(value).isAfter(dayjs(target))
	},
	message: 'The {_field_} should be after {target}.',
})

extend('timeAfter24Hr', {
	params: ['target'],
	validate(value, { target }) {
		return dayjs(value).isAfter(dayjs(target)) && dayjs(value).diff(dayjs(target), 'hour') >= 24
	},
	message: 'The {_field_} should be after {target} more than 24 hr.',
})

extend('timeAfterRangeByDate', {
	params: ['target'],
	validate(value, { target }) {
		return dayjs(value.start).isAfter(dayjs(target))
	},
	message: 'The start date of {_field_} should be after {target}.',
})

extend('timeAfterRange', {
	params: ['target', 'index'],
	validate(value, { target }) {
		return dayjs(value.start).isAfter(dayjs(target))
	},
	message: 'The start date of {_field_} should be after end date of Day {index}',
})

extend('equal', {
	params: ['answer'],
	validate(value, { answer }) {
		return value === Number(answer)
	},
	message: 'There must be {answer} answers.',
})
