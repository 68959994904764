import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/signin',
		name: 'signin',
		component: () => import('@/views/SigninView.vue'),
		meta: {
			layout: 'blank',
		},
	},
	{
		path: '/',
		name: 'home',
		component: () => import('@/views/HomeView.vue'),
	},
	{
		path: '/me/company',
		name: 'me-company',
		component: () => import('@/views/me/EditCompany.vue'),
	},
	{
		path: '/campaign',
		name: 'campaign',
		component: () => import('@/views/campaign/Index.vue'),
	},
	{
		path: '/campaign/create-stamp-card',
		name: 'campaign-create-stamp-card',
		component: () => import('@/views/campaign/CreateEditStampCard.vue'),
	},
	{
		path: '/campaign/edit-stamp-card/:id',
		name: 'campaign-edit-stamp-card',
		component: () => import('@/views/campaign/CreateEditStampCard.vue'),
		meta: {
			edit: true,
		},
	},
	{
		path: '/campaign/create-send-picture',
		name: 'campaign-create-send-picture',
		component: () => import('@/views/campaign/CreateEditSendPicture.vue'),
	},
	{
		path: '/campaign/edit-send-picture/:id',
		name: 'campaign-edit-send-picture',
		component: () => import('@/views/campaign/CreateEditSendPicture.vue'),
		meta: {
			edit: true,
		},
	},
	{
		path: '/campaign/create-watch-video',
		name: 'campaign-create-watch-video',
		component: () => import('@/views/campaign/CreateEditWatchVideo.vue'),
	},
	{
		path: '/campaign/edit-watch-video/:id',
		name: 'campaign-edit-watch-video',
		component: () => import('@/views/campaign/CreateEditWatchVideo.vue'),
		meta: {
			edit: true,
		},
	},
	{
		path: '/campaign/create-vote',
		name: 'campaign-create-vote',
		component: () => import('@/views/campaign/CreateEditVote.vue'),
	},
	{
		path: '/campaign/edit-vote/:id',
		name: 'campaign-edit-vote',
		component: () => import('@/views/campaign/CreateEditVote.vue'),
		meta: {
			edit: true,
		},
	},
	{
		path: '/campaign/create-questionnaire',
		name: 'campaign-create-questionnaire',
		component: () => import('@/views/campaign/CreateEditQuestionnaire.vue'),
	},
	{
		path: '/campaign/edit-questionnaire/:id',
		name: 'campaign-edit-questionnaire',
		component: () => import('@/views/campaign/CreateEditQuestionnaire.vue'),
		meta: {
			edit: true,
		},
	},
	{
		path: '/campaign/create-photo-hunt',
		name: 'campaign-create-photo-hunt',
		component: () => import('@/views/campaign/CreateEditPhotoHunt.vue'),
	},
	{
		path: '/campaign/edit-photo-hunt/:id',
		name: 'campaign-edit-photo-hunt',
		component: () => import('@/views/campaign/CreateEditPhotoHunt.vue'),
		meta: {
			edit: true,
		},
	},
	{
		path: '/campaign/create-point-collection-code',
		name: 'campaign-create-point-collection-code',
		component: () => import('@/views/campaign/CreateEditPointCollectionCode.vue'),
	},
	{
		path: '/campaign/edit-point-collection-code/:id',
		name: 'campaign-edit-point-collection-code',
		component: () => import('@/views/campaign/CreateEditPointCollectionCode.vue'),
		meta: {
			edit: true,
		},
	},
	{
		path: '/campaign/create-friends-get-friends',
		name: 'campaign-create-friends-get-friends',
		component: () => import('@/views/campaign/CreateEditFriendsGetFriends.vue'),
	},
	{
		path: '/campaign/edit-friends-get-friends/:id',
		name: 'campaign-edit-friends-get-friends',
		component: () => import('@/views/campaign/CreateEditFriendsGetFriends.vue'),
		meta: {
			edit: true,
		},
	},
	{
		path: '/campaign/create-lucky-draw',
		name: 'campaign-create-lucky-draw',
		component: () => import('@/views/campaign/CreateEditLuckyDraw.vue'),
	},
	{
		path: '/campaign/edit-lucky-draw/:id',
		name: 'campaign-edit-lucky-draw',
		component: () => import('@/views/campaign/CreateEditLuckyDraw.vue'),
		meta: {
			edit: true,
		},
	},
	{
		path: '/campaign/create-click-and-get-something',
		name: 'campaign-create-click-and-get-something',
		component: () => import('@/views/campaign/CreateEditClickAndGetSomething.vue'),
	},
	{
		path: '/campaign/edit-click-and-get-something/:id',
		name: 'campaign-edit-click-and-get-something',
		component: () => import('@/views/campaign/CreateEditClickAndGetSomething.vue'),
		meta: {
			edit: true,
		},
	},
	{
		path: '/campaign/create-dynamic',
		name: 'campaign-create-dynamic',
		component: () => import('@/views/campaign/CreateEditDynamic.vue'),
	},
	{
		path: '/campaign/edit-dynamic/:id',
		name: 'campaign-edit-dynamic',
		component: () => import('@/views/campaign/CreateEditDynamic.vue'),
		meta: {
			edit: true,
		},
	},
	{
		path: '/campaign/create-survey-template',
		name: 'campaign-create-survey-template',
		component: () => import('@/views/campaign/CreateEditSurveyTemplate.vue'),
	},
	{
		path: '/campaign/edit-survey-template/:id',
		name: 'campaign-edit-survey-template',
		component: () => import('@/views/campaign/CreateEditSurveyTemplate.vue'),
		meta: {
			edit: true,
		},
	},
	{
		path: '/campaign/:id/show',
		name: 'campaign-show',
		component: () => import('@/views/campaign/Show.vue'),
	},
	{
		path: '/campaign-result',
		name: 'campaign-result',
		component: () => import('@/views/campaign-result/Index.vue'),
	},
	{
		path: '/campaign-result/create/:type',
		name: 'campaign-result-create',
		component: () => import('@/views/campaign-result/CreateEdit.vue'),
	},
	{
		path: '/campaign-result/edit/:type/:id',
		name: 'campaign-result-edit',
		component: () => import('@/views/campaign-result/CreateEdit.vue'),
		meta: {
			edit: true,
		},
	},
	{
		path: '/campaign-landing',
		name: 'campaign-landing',
		component: () => import('@/views/campaign-landing/Index.vue'),
	},
	{
		path: '/campaign-landing/create',
		name: 'campaign-landing-create',
		component: () => import('@/views/campaign-landing/CreateEdit.vue'),
	},
	{
		path: '/campaign-landing/edit/:id',
		name: 'campaign-landing-edit',
		component: () => import('@/views/campaign-landing/CreateEdit.vue'),
		meta: {
			edit: true,
		},
	},
	{
		path: '/campaign-landing/show/:id',
		name: 'campaign-landing-show',
		component: () => import('@/views/campaign-landing/Show.vue'),
	},
	{
		path: '/campaign-additional-configs',
		name: 'campaign-additional-configs',
		component: () => import('@/views/campaign-additional-configs/Index.vue'),
	},
	{
		path: '/campaign-additional-configs/create',
		name: 'campaign-additional-configs-create',
		component: () => import('@/views/campaign-additional-configs/CreateEdit.vue'),
	},
	{
		path: '/campaign-additional-configs/edit/:id',
		name: 'campaign-additional-configs-edit',
		component: () => import('@/views/campaign-additional-configs/CreateEdit.vue'),
		meta: {
			edit: true,
		},
	},
	{
		path: '/campaign-additional-configs/show/:id',
		name: 'campaign-additional-configs-show',
		component: () => import('@/views/campaign-additional-configs/Show.vue'),
	},
	{
		path: '/reward',
		name: 'reward',
		component: () => import('@/views/reward/Index.vue'),
	},
	{
		path: '/reward/create',
		name: 'reward-create',
		component: () => import('@/views/reward/CreateEdit.vue'),
	},
	{
		path: '/reward/edit/:id',
		name: 'reward-edit',
		component: () => import('@/views/reward/CreateEdit.vue'),
		meta: {
			edit: true,
		},
	},
	{
		path: '/reward/show/:id',
		name: 'reward-show',
		component: () => import('@/views/reward/Show.vue'),
	},
	{
		path: '/admin',
		name: 'admin',
		component: () => import('@/views/admin/Index.vue'),
	},
	{
		path: '/admin/create',
		name: 'admin-create',
		component: () => import('@/views/admin/CreateEdit.vue'),
	},
	{
		path: '/admin/edit/:id',
		name: 'admin-edit',
		component: () => import('@/views/admin/CreateEdit.vue'),
		meta: {
			edit: true,
		},
	},
	{
		path: '/company',
		name: 'company',
		component: () => import('@/views/company/Index.vue'),
	},
	{
		path: '/company/create',
		name: 'company-create',
		component: () => import('@/views/company/CreateEdit.vue'),
	},
	{
		path: '/company/edit/:id',
		name: 'company-edit',
		component: () => import('@/views/company/CreateEdit.vue'),
		meta: {
			edit: true,
		},
	},
	{
		path: '/user',
		name: 'user',
		component: () => import('@/views/user/Index.vue'),
	},
	{
		path: '/user/:id/show',
		name: 'user-show',
		component: () => import('@/views/user/CreateEdit.vue'),
		meta: {
			mode: 'VIEW',
		},
	},
	{
		path: '/log',
		name: 'log',
		component: () => import('@/views/system/log/Log.vue'),
	},
	{
		path: '/log/show',
		name: 'log-show',
		component: () => import('@/views/system/log/LogShow.vue'),
	},
	{
		path: '/report',
		name: 'report-index',
		component: () => import('@/views/report/Index.vue'),
	},
	{
		path: '/report/member-report',
		name: 'report-member-report',
		component: () => import('@/views/report/MemberReport.vue'),
	},
	{
		path: '/report/campaign-report',
		name: 'report-campaign-report',
		component: () => import('@/views/report/CampaignReport.vue'),
	},
	{
		path: '/report/company-report',
		name: 'report-company-report',
		component: () => import('@/views/report/CompanyReport.vue'),
	},
	{
		path: '/report/entry-point-report',
		name: 'report-entry-point-report',
		component: () => import('@/views/report/EntryPointReport.vue'),
	},
	{
		path: '/report/reward-report',
		name: 'report-reward-report',
		component: () => import('@/views/report/RewardReport.vue'),
	},
	{
		path: '/report/additional-config-report',
		name: 'report-additional-config-report',
		component: () => import('@/views/report/AdditionalConfig.vue'),
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

export default router
