<script>
import menu from '@/components/menu.js'
import NavbarDropdownItem from './NavbarDropdownItem.vue'
import { mixin as clickaway } from 'vue-clickaway'
import { mapActions, mapState } from 'pinia'
import { useAuthStore } from '@/store/authStore'

export default {
	mixins: [clickaway],
	components: {
		NavbarDropdownItem,
	},
	data() {
		return {
			toggle: false,
			menu,
		}
	},
	computed: {
		...mapState(useAuthStore, ['isLoggedIn', 'isSuper', 'me']),
	},
	created() {
		this.getMe()
	},
	methods: {
		...mapActions(useAuthStore, {
			signout: 'signoutHandler',
			getMe: 'getMe',
		}),
		onClickAway() {
			this.toggle = false
		},
		signoutHandler() {
			this.signout().then(() => {
				this.$router.push({ name: 'signin' })
			})
		},
	},
	watch: {
		$route() {
			this.toggle = false
		},
	},
}
</script>

<template>
	<nav
		class="nm-navbar _dp-f _alit-ct _pst-f _t-0 _w-100pct"
		role="navigation"
		aria-label="main navigation"
		v-on-clickaway="onClickAway"
	>
		<div class="lo-container _h-ihr">
			<div class="_dp-f _g-10 _h-ihr _alit-ct _pdv-4 _w-100pct _alit-ct">
				<div class="_dp-f _jtfct-spbtw _w-100pct _w-us:md _alit-ct">
					<router-link to="/">
						<img
							class="logo _f-1"
							src="@/assets/image/tspace-logo-white.png"
							alt="TSPACE"
						/>
					</router-link>

					<a
						role="button"
						class="u-flex-middle _pd-4 _bdrd-4 _bgcl-content:hover _bgcl-opacity-10 _dp-n:md"
						@click="toggle = !toggle"
						style="width: 44px"
					>
						<i v-if="toggle" class="fa-regular fa-times _fs-8 _cl-white"></i>
						<i v-else class="fa-regular fa-bars _fs-8 _cl-white"></i>
					</a>
				</div>

				<div
					class="menu-container _alit-ct _jtfct-spbtw _f-1"
					:class="{ 'is-active': toggle }"
				>
					<div class="_dp-f _g-4">
						<template v-for="m in menu">
							<navbar-dropdown-item :menu="m" :key="m.name"></navbar-dropdown-item>
						</template>
					</div>

					<div class="nm-dropdown is-hover-open" @click="toggle = false">
						<div class="_dp-f _alit-ct">
							<span class="nm-badge _bgcl-accent _cl-accent-content">
								<span v-if="isSuper">{{ me.roleDisplay }}</span>
								<span v-else>{{ me.company.name }} ({{ me.roleDisplay }})</span>
								<i class="fa-regular fa-chevron-down _mgl-4"></i>
							</span>
						</div>

						<ul
							class="nm-menu is-card _bsd-100 _bdw-1 _bdcl-content _bdcl-opacity-15 _bdrd-4"
						>
							<li>
								<router-link v-if="!isSuper" :to="{ name: 'me-company' }">
									Profile company
								</router-link>
							</li>
							<li>
								<a @click.prevent="signoutHandler"> Sign out </a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</nav>
</template>

<style scoped lang="scss">
.nm-navbar {
	background: hsl(232, 46%, 16%);
	z-index: 5;
	height: var(--navbar-height);

	.logo {
		height: calc(var(--navbar-height) * 0.48);
	}
}

.menu-container {
	display: flex;
}

@media screen and (max-width: 1023px) {
	.menu-container {
		display: none;
	}

	.menu-container.is-active {
		position: fixed;
		z-index: 10;
		display: flex;
		flex-direction: column;
		gap: 12px;
		top: var(--navbar-height);
		left: 0;
		background: hsl(232, 46%, 25%);
		width: 100%;
		padding: 32px 24px 64px 24px;

		> div:first-child {
			width: 100%;
			flex-direction: column;
			margin-bottom: 24px;
		}
	}
}
</style>
