<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import BlankLayout from '@/layouts/BlankLayout.vue'

export default {
	computed: {
		layout() {
			const layouts = {
				default: DefaultLayout,
				blank: BlankLayout,
			}
			return layouts[this.$route.meta.layout || 'default']
		},
	},
}
</script>

<template>
	<component :is="layout"></component>
</template>
