<script>
import Navbar from '@/components/Navbar.vue'

export default {
	components: {
		Navbar,
	},
}
</script>

<template>
	<div>
		<navbar></navbar>

		<main>
			<div class="lo-container _pdbt-10">
				<router-view></router-view>
			</div>
		</main>
	</div>
</template>

<style lang="scss" scoped>
main {
	min-height: 100vh;
	padding-top: calc(var(--navbar-height) + 2.5rem);
	background: hsl(var(--hsl-base-200));
}
</style>
